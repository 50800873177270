function BillingInformation(props) {
  return (
    <form className="px-4 pb-36 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
      <div className="mx-auto max-w-lg lg:max-w-none">
        <section aria-labelledby="billing-heading" className="mt-10">
          <h2
            id="billing-heading"
            className="text-lg font-medium text-gray-900"
          >
            Billing address
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <label
                htmlFor="billing_address"
                className="block text-sm font-medium text-gray-700"
              >
                Address
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="billing_address"
                  name="billing_address_1"
                  value={props.state.billing_address_1}
                  onChange={props.handleChange}
                  autoComplete="address-line1"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="billing_apartment"
                className="block text-sm font-medium text-gray-700"
              >
                Apartment, suite, etc.
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="billing_apartment"
                  name="billing_address_2"
                  value={props.state.billing_address_2}
                  onChange={props.handleChange}
                  autoComplete="address-line2"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="billing_city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="billing_city"
                  name="billing_city"
                  value={props.state.billing_city}
                  onChange={props.handleChange}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="billing_region"
                className="block text-sm font-medium text-gray-700"
              >
                State / Province
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="billing_region"
                  name="billing_state"
                  value={props.state.billing_state}
                  onChange={props.handleChange}
                  autoComplete="address-level1"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="billing_postal-code"
                className="block text-sm font-medium text-gray-700"
              >
                Postal code
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="billing_postal-code"
                  name="billing_zip_code"
                  value={props.state.billing_zip_code}
                  onChange={props.handleChange}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </form>
  );
}

export default BillingInformation;
